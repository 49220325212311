import graphql from 'babel-plugin-relay/macro';
import moment from 'moment';
import { get } from 'lodash';
import React from 'react';
import { createFragmentContainer } from 'react-relay';
import styled from 'styled-components';
import {
  Icon,
  Divider,
  Header,
  Segment,
  Form,
  Button,
  Image,
  Grid,
  Label,
  List,
  Container,
} from 'semantic-ui-react';

import {
  CreateResponseMutation,
  DeleteResponseMutation,
  UpdateThreadMutation,
  UpdateResponseMutation,
} from '../mutations';
import { withRelay } from '../relay';
import { withRouter } from 'found';
import API from '../helpers/api';

import FileUploadButton from './FileUploadButton';

const Timestamp = styled.div`
  width: 100%;
  text-align: ${(props) => props.position || 'left'};
`;

const Response = styled.div`
  white-space: pre-wrap;
`;

const style = {
  backgroundColor: '#f2f5fa',
  overflowY: 'scroll',
  // maxHeight: "100vh",
  paddingTop: 14,
  paddingLeft: 14,
  paddingRight: 14,
  width: '100%',
  // margin: 0,
  height: 'calc(100vh - 72px)',
  fallbacks: [
    { maxHeight: '-moz-calc(100vh - 72px)' },
    { maxHeight: '-webkit-calc(100vh - 72px)' },
    { maxHeight: '-o-calc(100vh - 72px)' },
  ],
};

class Consultation extends React.Component {
  state = {
    isEditting: false,
    text: '',
    files: [],
    isSubmitting: false,
    responseId: null,
    textAreaHeight: 100,
  };

  handleUploadButton = (newFiles) => {
    const { files } = this.state;
    this.setState({ files: [...files, ...newFiles] });
  };

  handleUnattachFiles = () => {
    this.setState({ files: [] });
  };

  handleEditClick = (response) => {
    const { isActive } = this.props.thread;
    if (!isActive) {
      if (
        !window.confirm('Сигурни ли сте, че искате да отключите консултацията?')
      ) {
        return;
      }

      const input = {
        id: this.props.thread.id,
        isActive: true,
      };

      const onSuccess = () => {
        console.log('Success');
      };

      const onFailure = () => console.log('onFailure');

      UpdateThreadMutation({ input }, onSuccess, onFailure);
    }
    this.setState({
      isEditting: true,
      text: response.text,
      responseId: response.id,
    });
  };

  handleCancelEditClick = () => {
    this.setState({ isEditting: false, text: '', responseId: null });
  };

  handleEditButtonClick = async (response) => {
    const { text, files } = this.state;
    if (!text.length && !files.length) {
      return;
    }

    this.setState({ isSubmitting: true });

    const { thread } = this.props;
    const { responseId } = this.state;
    const input = {
      id: responseId,
      threadId: thread.id,
      text,
    };

    if (files.length > 0) {
      this.props.onFileUploadStart();
      const filesData = files.map((file) => ({
        name: file.name,
        type: file.type,
      }));

      const body = JSON.stringify({ filesData });
      const response = await API.post('signS3Uploads', { body });
      const {json: signedUrls} = response;

      const uploadedFiles = [];
      for (const signedUrl of signedUrls) {
        const file = files.find((file) => file.name === signedUrl.fileName);
        if (!file) {
          console.log('no file!');
          // handle error
          return null;
        }
        const response = await API.uploadFileToS3(file, signedUrl.uploadURL);
        if (response && response.ok) {
          uploadedFiles.push(signedUrl);
        }
      }
      if (uploadedFiles.length > 0) {
        input.uploadedFiles = uploadedFiles;
      }
    }

    const onSuccess = () => {
      console.log('onSuccess');

      if (files.length > 0) {
        this.props.onFileUploadEnd();
      }

      this.scrollEnd.scrollIntoView({ behavior: 'smooth' });

      this.setState({
        text: '',
        isSubmitting: false,
        files: [],
        responseId: null,
        isEditting: false,
      });
    };

    const onFailure = () => {
      console.log('onFailure');

      if (files.length > 0) {
        this.props.onFileUploadEnd();
      }

      this.setState({
        isSubmitting: false,
        isEditting: false,
      });
    };

    console.log('input', input);

    UpdateResponseMutation(
      {
        input,
      },
      onSuccess,
      onFailure
    );
  };

  handleDeleteClick = (response) => {
    if (!window.confirm('Сигурни ли сте, че искате да изтриете коментара?')) {
      return;
    }

    const { viewer, thread } = this.props;
    const connectionName = 'Consultation_responses';
    const filters = { filterBy: { threadId: thread.id } };
    const input = {
      id: response.id,
    };

    const onSuccess = () => {
      console.log('Success');
    };
    const onFailure = () => console.log('onFailure');

    DeleteResponseMutation(
      { input, connectionName, viewer, filters },
      onSuccess,
      onFailure
    );
  };

  handleAnswerButtonClick = async () => {
    const { text, files } = this.state;
    if (!text.length && !files.length) {
      return;
    }

    this.setState({ isSubmitting: true });

    const { thread, viewer } = this.props;
    const input = {
      threadId: thread.id,
      text,
    };

    if (files.length > 0) {
      this.props.onFileUploadStart();
      const filesData = files.map((file) => ({
        name: file.name,
        type: file.type,
      }));

      const body = JSON.stringify({ filesData });
      const response = await API.post('signS3Uploads', { body });
      const {json: signedUrls} = response;

      const uploadedFiles = [];
      for (const signedUrl of signedUrls) {
        const file = files.find((file) => file.name === signedUrl.fileName);
          if (!file) {
            console.log('no file!');
            // handle error
            return null;
          }

          const response = await API.uploadFileToS3(file, signedUrl.uploadURL);
          if (response && response.ok) {
            uploadedFiles.push(signedUrl);
          }
        }

        if (uploadedFiles.length > 0) {
          input.uploadedFiles = uploadedFiles;
        }
    }

    const connectionName = 'Consultation_responses';
    const filters = { filterBy: { threadId: thread.id } };

    const onSuccess = () => {
      console.log('onSuccess');

      if (files.length > 0) {
        this.props.onFileUploadEnd();
      }

      this.scrollEnd.scrollIntoView({ behavior: 'smooth' });

      this.setState({ text: '', isSubmitting: false, files: [] });
    };

    const onFailure = () => {
      console.log('onFailure');

      if (files.length > 0) {
        this.props.onFileUploadEnd();
      }

      this.setState({ isSubmitting: false });
    };

    CreateResponseMutation(
      {
        input,
        connectionName,
        viewer,
        filters,
      },
      onSuccess,
      onFailure
    );
  };

  handleLockButtonClick = () => {
    if (
      !window.confirm('Сигурни ли сте, че искате да приключите консултацията?')
    ) {
      return;
    }

    const input = {
      id: this.props.thread.id,
      isActive: false,
    };

    const onSuccess = () => {
      console.log('Success');

      this.scrollEnd.scrollIntoView({ behavior: 'smooth' });
    };
    const onFailure = () => console.log('onFailure');

    UpdateThreadMutation({ input }, onSuccess, onFailure);
  };

  handleUnlockButtonClick = () => {
    if (
      !window.confirm('Сигурни ли сте, че искате да отключите консултацията?')
    ) {
      return;
    }

    const input = {
      id: this.props.thread.id,
      isActive: true,
    };

    const onSuccess = () => {
      console.log('Success');
    };

    const onFailure = () => console.log('onFailure');

    UpdateThreadMutation({ input }, onSuccess, onFailure);
  };

  handleTextAreaChange = (e) => {
    const { scrollHeight } = e.target;
    const { value } = e.target;
    const textAreaHeight =
      scrollHeight <= 100 || value === ''
        ? 100
        : scrollHeight > window.innerHeight - 300
        ? window.innerHeight - 300
        : scrollHeight;
    //HACK: Height of the TextArea changes with one pixel when typing without this hack
    if (
      textAreaHeight === this.state.textAreaHeight ||
      textAreaHeight === this.state.textAreaHeight + 1
    ) {
      this.setState({ text: value });
    } else {
      this.setState({ text: value, textAreaHeight: textAreaHeight });
    }
  };

  handleTextAreaFocus = (e) => {
    const { isEditting } = this.state;
    this.props.onTextAreaFocus();
    if (isEditting) {
      const { scrollHeight } = e.target;
      const textAreaHeight =
        scrollHeight < 100
          ? 100
          : scrollHeight > window.innerHeight - 300
          ? window.innerHeight - 300
          : scrollHeight;

      this.setState({
        textAreaHeight: textAreaHeight,
      });
    } else {
      this.scrollEnd.scrollIntoView({ behavior: 'smooth' });
    }
  };

  renderUserIcon = (user) => {
    const firstLetter = (w) => (w ? w[0] : '');
    const initials = [user.firstName, user.lastName].map(firstLetter).join(' ');

    return (
      <Image
        // src={"/karen.jpg"}
        circular
        style={{
          height: 55,
          width: 55,
          position: 'absolute',
          right: 0,
          marginTop: 14,
          // borderColor: "red",
          // borderWidth: 2,
          // borderStyle: "solid"
        }}
      >
        {/* {!isAdmin ? ( */}
        <Label
          size="large"
          content={initials}
          style={{
            height: 55,
            width: 55,
            textAlign: 'center',
            paddingTop: 20,
          }}
        />
        {/* ) : null} */}
      </Image>
    );
  };

  renderBubble = (response) => {
    const isAdmin = this.props.viewer.type === 'admin';
    const isAdminResponse =
      response.user.rowId === this.props.viewer.rowId &&
      response.user.type === 'admin';
    const { viewer, isMobile } = this.props;
    const { user, files } = response;

    const isRight = viewer.rowId === user.rowId;

    return (
      <Grid
        key={response.id}
        style={{
          paddingBottom: isMobile ? 0 : 1,
          paddingRight: isMobile ? 5 : 15,
          paddingLeft: isMobile ? 5 : 15,
        }}
        id={response.id}
      >
        {/* <Grid.Row> */}
        {!isRight && !isMobile && (
          <Grid.Column
            width={1}
            style={{
              // borderColor: "blue",
              // borderWidth: 2,
              // borderStyle: "solid",
              padding: 0,
            }}
          >
            {this.renderUserIcon(user)}
          </Grid.Column>
        )}
        <Grid.Column
          floated={isRight ? 'right' : 'left'}
          width={isMobile ? 14 : 15}
        >
          <Segment
            raised
            style={{
              background: 'white',
              borderRadius: '.4em',
              borderWidth: '20',
              borderColor: 'transparent',
              borderStyle: 'solid',
              marginBottom: 5,
              fontSize: 15,
            }}
          >
            <Response>{response.text}</Response>
            {files.length > 0 && (
              <div>
                <Divider />
                <p style={{ fontSize: 15 }}>
                  <Icon name="attach" />
                  Прикачени файлове:
                </p>
                {files.map((file) => (
                  <p key={file.id}>
                    <a href={`//${window.SERVER_URL}/attachment/${file.id}`}>
                      {file.name}
                    </a>
                  </p>
                ))}
              </div>
            )}
          </Segment>
          {!isMobile ? (
            <Container style={{ width: '100%' }}>
              <Timestamp {...{ position: isRight ? 'right' : 'left' }}>
                {isAdmin && isAdminResponse && (
                  <Button
                    style={{
                      backgroundColor: 'transparent',
                      color: '#4183c4',
                      fontWeight: 'normal',
                      padding: 10,
                    }}
                    onClick={() => this.handleEditClick(response)}
                  >
                    Редактирай{' '}
                  </Button>
                )}
                {isAdmin && isAdminResponse && (
                  <Button
                    style={{
                      backgroundColor: 'transparent',
                      color: '#4183c4',
                      fontWeight: 'normal',
                      padding: 10,
                    }}
                    as="a"
                    onClick={() => this.handleDeleteClick(response)}
                  >
                    Изтрий{' '}
                  </Button>
                )}
                {moment(response.createdAt).format('LLL')}
                {response.createdAt !== response.updatedAt &&
                  `  /  Редaктирано на ${moment(response.updatedAt).format(
                    'LLL'
                  )}`}
              </Timestamp>
            </Container>
          ) : (
            <Container>
              <Timestamp {...{ position: isRight ? 'right' : 'left' }}>
                {moment(response.createdAt).format('DD-MM-YYYY HH:mm')}
              </Timestamp>
              {isAdmin && isAdminResponse && (
                <Timestamp {...{ position: isRight ? 'right' : 'left' }}>
                  <List horizontal>
                    <List.Item
                      as="a"
                      onClick={() => this.handleEditClick(response)}
                    >
                      Редактирай
                    </List.Item>
                    <List.Item
                      as="a"
                      onClick={() => this.handleDeleteClick(response)}
                    >
                      Изтрий
                    </List.Item>
                  </List>
                </Timestamp>
              )}
              {response.createdAt !== response.updatedAt && (
                <Timestamp {...{ position: isRight ? 'right' : 'left' }}>
                  Ред. на{' '}
                  {moment(response.updatedAt).format('DD-MM-YYYY HH:mm')}
                </Timestamp>
              )}
            </Container>
          )}
        </Grid.Column>
        {isRight && !isMobile && (
          <Grid.Column
            width={1}
            style={{
              // borderColor: "blue",
              // borderWidth: 2,
              // borderStyle: "solid",
              padding: 0,
            }}
          >
            {this.renderUserIcon(user)}
          </Grid.Column>
        )}
        {/* </Grid.Row> */}
      </Grid>
    );
  };

  render() {
    const { viewer, isMobile, width } = this.props;
    if (!viewer) {
      return null;
    }

    const {
      service: { description },
      user: { id, firstName, lastName },
      createdAt,
      deactivatedAt,
      title,
      isActive,
      isSubmitting,
      payment,
    } = this.props.thread;

    const smallButtons = width < 450;

    const { files, isEditting, textAreaHeight } = this.state;

    const isAdmin = viewer.type === 'admin';

    const responsesHeight = isMobile
      ? document.body.clientHeight - textAreaHeight - 160
      : window.innerHeight - textAreaHeight - 275;

    const responses = (get(this.props.viewer, 'responses.edges') || []).map(
      ({ node }) => node
    );

    const paymentId = payment ? payment.refId || payment.rowId : 'N/A';

    return (
      <div
        style={{
          ...style,
          width: this.props.componentWidth || '',
        }}
      >
        <Header as="h3" dividing>
          <Header.Content>
            {title}
            <Header.Subheader>{description}</Header.Subheader>
            {/* TODO: Fix user email for consultations created by admin */}
            {isAdmin && (
              <Header.Subheader>
                от:{' '}
                <span
                  onMouseEnter={() => {
                    document.body.style.cursor = 'pointer';
                  }}
                  onMouseLeave={() => {
                    document.body.style.cursor = 'default';
                  }}
                  onClick={
                    !isMobile
                      ? () => this.props.router.push('/patients/' + id)
                      : undefined
                  }
                  style={{
                    color: '#4183c4',
                  }}
                >
                  {firstName} {lastName}
                </span>
              </Header.Subheader>
            )}
            {isAdmin && (
              <Header.Subheader>
                номер на плащане:{' '}
                <span
                  onMouseEnter={() => {
                    document.body.style.cursor = 'pointer';
                  }}
                  onMouseLeave={() => {
                    document.body.style.cursor = 'default';
                  }}
                  onClick={
                    !isMobile
                      ? () => this.props.router.push('/payments/' + payment.id)
                      : undefined
                  }
                  style={{
                    color: '#4183c4',
                  }}
                >
                  {paymentId}
                </span>
              </Header.Subheader>
            )}
            <Header.Subheader>{`започната на ${moment(createdAt).format(
              'LLL'
            )}`}</Header.Subheader>
          </Header.Content>
        </Header>
        <div
          style={{
            maxHeight: responsesHeight,
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          {responses.length > 0 && responses.map(this.renderBubble)}
          <div ref={(ref) => (this.scrollEnd = ref)} />
        </div>
        {isActive && (
          <Form
            reply
            style={{
              position: 'fixed',
              bottom: 65,
              width: this.props.componentWidth
                ? 'inherit'
                : isMobile
                ? '97%'
                : '100%',
              paddingLeft: 0,
            }}
          >
            <Form.TextArea
              style={{
                overflowY: 'scroll',
                width: isMobile ? '95%' : '97%',
                height: textAreaHeight,
                minHeight: 100,
                fontSize: 15,
              }}
              placeholder="Напишете отговор"
              onFocus={this.handleTextAreaFocus}
              onChange={this.handleTextAreaChange}
              value={this.state.text}
            />
          </Form>
        )}
        <Container
          style={{
            position: 'absolute',
            bottom: 15,
            width: isMobile ? '95%' : '97%',
          }}
        >
          {!isActive && (
            <Divider
              style={{
                paddingTop: 50,
                fontSize: isMobile && 12,
                width: 'inherit',
              }}
              horizontal
            >
              Консултацията беше приключена на
              <p>{moment(deactivatedAt).format('LLL')}</p>
            </Divider>
          )}
          {!isEditting && (
            <Button
              content={!smallButtons ? 'Отговори' : 'Oтг.'}
              labelPosition={!smallButtons && 'left'}
              icon="edit"
              primary
              loading={isSubmitting}
              disabled={!isActive}
              onClick={this.handleAnswerButtonClick}
            />
          )}
          {isEditting && (
            <Button
              content={!smallButtons ? 'Запази' : 'Запази'}
              labelPosition={!smallButtons && 'left'}
              icon="edit"
              primary
              loading={isSubmitting}
              disabled={!isActive}
              onClick={this.handleEditButtonClick}
            />
          )}
          <Button.Group>
            <FileUploadButton
              content={
                isMobile
                  ? null
                  : files.length
                  ? `Прикачи файлове (${files.length})`
                  : 'Прикачи файлове'
              }
              labelPosition={!isMobile && 'left'}
              icon="upload"
              disabled={!isActive || isSubmitting}
              onSelect={this.handleUploadButton}
            />
            {files.length > 0 && (
              <Button icon="close" onClick={this.handleUnattachFiles} />
            )}
          </Button.Group>

          {!isEditting && isAdmin && (
            <Button
              floated="right"
              disabled={!isActive || isSubmitting}
              color="red"
              content={!isMobile ? 'Приключи консултация' : null}
              labelPosition={!isMobile && 'left'}
              icon="close"
              onClick={this.handleLockButtonClick}
            />
          )}
          {!isEditting && isAdmin && !isActive && (
            <Button
              floated="right"
              color="green"
              content={!isMobile ? 'Отключи консултация' : null}
              labelPosition={!isMobile && 'left'}
              icon="unlock alternate"
              onClick={this.handleUnlockButtonClick}
            />
          )}
          {isEditting && (
            <Button
              floated="right"
              color="red"
              content={!isMobile ? 'Откажи' : null}
              labelPosition={!isMobile && 'left'}
              icon="close"
              onClick={this.handleCancelEditClick}
            />
          )}
        </Container>
      </div>
    );
  }
}

const Query = graphql`
  query Consultation_Query($filterBy: ResponseFilterInput) {
    viewer {
      ...Consultation_viewer @arguments(filterBy: $filterBy)
    }
  }
`;

// TODO: Decide if we want responses to be a connection here
const ConsultationContainer = createFragmentContainer(Consultation, {
  viewer: graphql`
    fragment Consultation_viewer on User
      @argumentDefinitions(filterBy: { type: "ResponseFilterInput" }) {
      id
      type
      rowId
      responses(
        filterBy: $filterBy
        first: 10000
        orderBy: [["createdAt", "ASC"]]
      ) @connection(key: "Consultation_responses", filters: ["filterBy"]) {
        edges {
          node {
            id
            text
            files {
              id
              name
            }
            user {
              rowId
              firstName
              lastName
              type
            }
            createdAt
            updatedAt
          }
        }
      }
    }
  `,
  thread: graphql`
    fragment Consultation_thread on Thread {
      id
      service {
        description
      }
      user {
        id
        rowId
        firstName
        lastName
        email
        phoneNumber
      }
      createdAt
      deactivatedAt
      title
      isActive
      payment {
        id
        rowId
        refId
      }
    }
  `,
});

ConsultationContainer.getVariables = (props) => {
  return { filterBy: { threadId: props.thread.id } };
};

export default withRouter(withRelay(ConsultationContainer, Query));
