export function getCookieDomain() {
  const domain = window.location.hostname;

  if (process.env.NODE_ENV === 'development') {
    return domain;
  }

  const parts = domain.split('.');
  if (parts.length > 2) {
    parts.shift();
  }

  return parts.join('.');
}

export function getSubdomain() {
  const domain = window.location.hostname;
  const parts = domain.split('.');
  return parts[0];
}

